import sanitizeHtml from 'sanitize-html'

const withHtmlContent = (content) => {
  const sanitizeOption = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe', 'img', 'jodit']),
    allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
    allowIframeRelativeUrls: true,
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': ['*'],
      iframe: ['src'],
    },
  }
  return content
    ? { dangerouslySetInnerHTML: { __html: sanitizeHtml(content, sanitizeOption) } }
    : {}
}

export default withHtmlContent
